@import '~texkit/dist/styles/variables';

.whats-this-modal {
    padding: 0px;    

    & .modal-body {
        padding: 72px 80px 48px;
    }

    @media screen and (max-width: 600px) {
        & .modal-body {
            height: auto !important;
        }

        & .modal-header,
        & .modal-body {
            padding-left: 24px;
            padding-right: 24px;
        }
    }

    & .whats-this-modal__content {
        margin-bottom: 48px;
    }

    & .whats-this-modal__header {
        color: $color-blue;
        text-align: center;
        font-size: 40px;
        font-style: normal;
        font-weight: 700;
        line-height: 44px;
        margin-bottom: $margin;

        @media screen and (max-width: 600px) {
            font-size: 24px;
        }
    }

    & .whats-this-modal__sub-header {
        color: $color-black;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 32px;
        margin-top: 16px;
    }

    & .whats-this-modal__card-display {
        width: 686px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        gap: 64px;

        & > div {
            width: 311px;
        }

        & .whats-this-modal__card-display-header {
            color: #20294f;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
        }

        & .whats-this-modal__card-display-description {
            color: #20294f;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }
        
        @media screen and (max-width: 600px) {
            flex-direction: column;
        }
    }
    
    & .whats-this-modal__footer {        
        @media screen and (max-width: 600px) {
            margin: $margin;
            
            .modal-button {
                width: 100%;
            }
        }
    }
}