@import '~texkit/dist/styles/variables';

.add-payment-account-modal {
  .modal-body {
    padding: 16px 48px 0px;

    .form-actions {
      justify-content: center;
    }
  }
  
  .modal-title {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 20px;    
    line-height: 38px;
  }

  .payment-frame {
    height: 1475px;
    margin: 0 auto;

    @media #{$mq-min-sm} {
      height: 1445px;
    }
  }

  .tx-payment-card-form__header {         
    margin-bottom: 32px;
  }

  .alert-container {
    margin-bottom: 32px;
  }

  .modal-close-button {
    margin-top: 0;
    color: $color-blue;    
  }
  
  @media screen and (max-width: 600px) {
    .modal-body {
      padding: 64px 24px 104px;
  
      .form-actions-wrapper {
        padding: 0 24px;
      }
      
      .form-actions {
        justify-content: center;
      }
    }

    .modal-title {
      font-size: 24px;
      font-weight: 700;
      line-height: normal;    
      line-height: normal;
      text-align: left;
    }

    .modal-close-button {
      margin-top: 0;
      color: $color-blue;    
    }
    
    .tx-payment-card-form__header {     
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
    }
  }  
}
