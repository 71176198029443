@import '~texkit/dist/styles/variables';

.edit-payment-account-modal {
  @media #{$mq-max-xs} {
    height: 100vh;
  }

  &.ios {
    @media only screen and (max-width: 780px) {
      overflow-y: scroll;
      height: 90%;
      padding-bottom: 66px;
      -webkit-overflow-scrolling: touch;

      .tx-autocomplete__list__item button {
        font-family: WorkSans, AvenirNext, sans-serif;
        font-size: 16px;
        color: $color-black;
      }
    }
  }
  
  .modal-body {
    padding: 16px 64px 0px;

    .form-actions {
      justify-content: center;
    }
  }
  
  .modal-title {    
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px; 
  }
  
  .payment-frame {
    height: 1460px;
    margin: 0 auto;

    @media #{$mq-min-sm} {
      height: 1180px;
    }
  }

  .iframe-error {
    margin-top: ( $margin * 3 );
    text-align: left;
  }

  .tx-form-actions__content {
    justify-content: center;
  }

  .alert-container {
    margin-bottom: 32px;
  }

  @media screen and (max-width: 600px) {
    .modal-body {
      padding: 64px 24px 104px;
  
      .form-actions-wrapper {
        padding: 0 24px;
      }

      .form-actions {
        justify-content: center;
      }
    }

    .modal-title {
      font-size: 24px;
      font-weight: 700;
      line-height: normal;    
      line-height: normal;
      text-align: left;
    }

    .tx-payment-card-form__header {     
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
    }

    .form-actions-item.spanish-only {
      padding: 4px 0;
      .tx-btn {
        padding: 0 32px
      }
    }
  } 
  
  .modal-close-button {
    margin-top: 0;
    color: $color-blue;    
  }

  .truncate-button {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
  }  
}
